import { ChangeDetectorRef, Component, Inject, Input, OnInit } from '@angular/core';
import { ProcurantChargesOverlayContainerComponent } from '../procurant-charges-overlay-container/procurant-charges-overlay-container.component';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ChargesService } from '../../../services/charges.service';
import { OneAuthService, OneNotifyService } from 'one-auth';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PO_MODFLAG, constant, SUBSCRIBED_APPS, MODULE_CODE, FEATURE_CODE } from 'common-lib';

@Component({
  selector: 'add-charges-overlay',
  templateUrl: './add-charges-overlay.component.html',
  styleUrls: ['./add-charges-overlay.component.scss']
})
export class AddChargesOverlayComponent implements OnInit {
  // (RD)PMB-7504 supplier order details UI change
  addchargesForm: FormGroup;
  modalData: any;
  applyByCode = 'FLAT';
  isEdit: boolean = false;
  isShowProrateToLine = true;
  invoiceStatus: boolean = false;
  invoiceId: number;
  invoiceLineId: number;
  poId: number;
  poLineId: number;
  chargeAddEdit: number = 1; // 1. Add
  charges: any[];
  chargeAPPLY_BY: any[];
  chargePRORATE_BY: any[];
  isSave: boolean = false;
  isEditChargeExist = 0;
  addedBy: string;
  chargeFlag: boolean = false;
  isEditForm: boolean = false;
  page: any;
  @Input() data: any;
  invoiceoStatus: boolean;
  permissions: boolean = true;
  isHideAddCharges: boolean;
  poStatus: boolean = false;
  isPermissionFetched: boolean = false;

  constructor(private notify: OneNotifyService, private chargesOverlayContainer: ProcurantChargesOverlayContainerComponent, private cdr: ChangeDetectorRef, public auth: OneAuthService, @Inject(MAT_DIALOG_DATA) public dialogData, private fb: FormBuilder, private service: ChargesService) { }

  ngOnInit(): void {
    if (this.data != null) {
      this.isShowProrateToLine = this.data[1];
      this.addchargesForm = this.fb.group({
        addChargesArray: this.fb.array([this.createChargeArray()]),
      });
      this.poStatus = this.data[2];
      if (this.data[0][0].chargeName)
        this.isEditChargeExist = this.data[0].length;
      this.setPolineids(this.data[0]);
      this.addedBy = this.data[3]
      //console.log("flag",this.data[4]);
      this.getCharges();
      //this.getChargeSetting();
      this.isHideAddCharges = this.data[4];
    }
  }

  // getCharges() {
  //   this.service.getCharges('ORDER').subscribe(resp => {
  //     this.charges = resp;
  //     this.charges.forEach(element => {
  //       let name: string;
  //       element.chargeName = element.name;
  //       name = element.name + ' | ' + element.chargeTypecode + ((element.freight == true) ? ' - Freight' : '');
  //       element.name = name;
  //     });
  //   })
  // }

  // getChargeSetting() {
  //   this.service.getChargeSetting("APPLY_BY").subscribe(resp => {
  //     this.chargeAPPLY_BY = resp;
  //   })

  //   this.service.getChargeSetting("PRORATE_BY").subscribe(resp => {
  //     this.chargePRORATE_BY = resp;
  //   })
  // }

  getCharges() {
    this.service.getCharges('ORDER').subscribe(resp => {
      this.charges = resp;
      this.charges.forEach(element => {
        let name: string;
        element.chargeName = element.name;
        name = element.name + ' | ' + element.chargeTypecode + ((element.freight == true) ? ' - Freight' : '');
        element.name = name;
      });
      this.getChargeSetting();
    })
  }

  getChargeSetting() {
    console.log('in add');
    this.service.getChargeSetting("APPLY_BY").subscribe(resp => {
      this.chargeAPPLY_BY = resp;
      this.service.getChargeSetting("PRORATE_BY").subscribe(resp1 => {
        this.chargePRORATE_BY = resp1;
        this.isHideAddCharges = this.data[4];
        //(OL) PMB-7713 issue fix for setting defaukt to FLAT
        this.isPermissionFetched = true;
      })
    })
  }

  setPolineids(data) {
    if (data.length > 0) {
      if (this.isShowProrateToLine)
        this.poId = data[0].poId;
      else {
        this.poId = data[0].poId;
        this.poLineId = data[0].poLineId;
      }
    }
  }


  createChargeArray() {
    const decimalPatern = '^[0-9]+(\.[0-9]{1,4})?$';
    return this.fb.group({
      id: [],
      poId: [],
      poLineId: [],
      chargeId: [''],
      chargeName: [''],
      name: ['', Validators.compose([Validators.required])],
      applyOnModuleCode: [''],
      displayToAppCode: [''],
      companyName: [''],
      chargeAmt: ['', Validators.compose([Validators.pattern(decimalPatern), Validators.max(99999.9999), Validators.min(0.0001), Validators.required])],
      chargeTypeId: [],
      chargeTypeCode: [''],
      chargeCode: [''],
      applyByCode: [this.applyByCode, Validators.compose([Validators.required])],
      includedInLC: [],
      inclProdCost: [],
      prorateById: [''],
      prorateByCode: ['', this.isShowProrateToLine ? Validators.compose([Validators.required]) : null],
      addedBy: [''],
      freight: []
    });
  }

  setChargeArray(val) {
    const decimalPatern = '^[0-9]+(\.[0-9]{1,4})?$';
    let name = val.chargeName + ' | ' + val.chargeTypeCode + ((val.freight == true) ? ' - Freight' : '');
    return this.fb.group({
      id: [val.id],
      poId: [val.poId],
      poLineId: [val.poLineId],
      chargeId: [val.chargeId],
      chargeName: [val.chargeName],
      applyOnModuleCode: [val.applyOnModuleCode],
      displayToAppCode: [val.displayToAppCode],
      companyName: [val.companyName],
      displayToSupplier: [val.displayToSupplier],
      name: [name, Validators.compose([Validators.required])],
      chargeAmt: [val.chargeAmt, Validators.compose([Validators.pattern(decimalPatern), Validators.max(99999.9999), Validators.min(0), Validators.required])],
      chargeTypeId: [val.chargeTypeId],
      chargeTypeCode: [val.chargeTypeCode],
      chargeCode: [val.chargeCode],
      applyByCode: [val.applyByCode, Validators.compose([Validators.required])],
      includedInLC: [val.includedInLC],
      inclProdCost: [val.inclProdCost],
      prorateById: [val.prorateById],
      prorateByCode: [val.prorateByCode, this.isShowProrateToLine ? Validators.compose([Validators.required]) : null],
      addedBy: [val.addedBy],
      freight: [val.freight]
    });
  }

  setValidator(field): FormControl {
    var validatorArray = [];
    validatorArray.push(Validators.required);
    return this.fb.control('', validatorArray);
  }

  ngAfterViewInit() {
    this.isEdit = this.modalData != undefined ? true : false;
    this.cdr.detectChanges();
  }

  setProrateByValidation() {
    if (this.isShowProrateToLine) {
      const controlAdd = <FormArray>this.addchargesForm.controls['addChargesArray'];
      for (let i in controlAdd) {
        controlAdd.at(+i).get('prorateByCode').setValidators(Validators.required);
        controlAdd.at(+i).get('prorateByCode').updateValueAndValidity();
      }
    } else {
      const control = <FormArray>this.addchargesForm.controls['addChargesArray'];
      for (let i in control) {
        control.at(+i).get('prorateByCode').setValidators(null);
        control.at(+i).get('prorateByCode').markAsUntouched();
        control.at(+i).get('prorateByCode').updateValueAndValidity();
      }
    }
  }

  public chargeSetValidator() {
    let chargesArray;
    let control;
    chargesArray = this.addchargesForm.controls['addChargesArray'].value;
    control = <FormArray>this.addchargesForm.controls['addChargesArray'];
    let j: number = 0;
    let invalid = false;
    if (control.length > 0) {
      for (let i in chargesArray) {
        if (this.isShowProrateToLine) {
          if (chargesArray[i].prorateByCode == '') {
            control.at(+i).get('prorateByCode').setErrors({ required: true })
            invalid = true;
          }
        }
        j++;
      }
    }
    return invalid;
  }

  setProratedBy(proratedBy: any): void {
    if (proratedBy) {
      let chrg = this.charges.find(chrge => chrge.name == proratedBy);
      this.addchargesForm.get('prorateByCode').setValue(chrg.id);
    }
  }

  setChargesValue(charge: any, index?: number): void {    
    if (charge) {
      let chrg = this.charges.find(chrge => chrge.chargeCode == charge);
      let displayTo: string = '';
      let displayToArray: any = this.data[3] == PO_MODFLAG[1] ? chrg.displayToBuyer : chrg.displayToSupplier;
      if (displayToArray) {
        Object.keys(displayToArray).forEach(index => {
          displayTo += displayToArray[index].toAppCode + ',';
        });
        displayTo = displayTo.substr(0, displayTo.length - 1);
      }
      let applyOnModuleCodes: string = '';
      if (chrg.appliedOn) {
        Object.keys(chrg.appliedOn).forEach(index => {
          applyOnModuleCodes += chrg.appliedOn[index].onModuleCode + ',';
        });
        applyOnModuleCodes = applyOnModuleCodes.substr(0, applyOnModuleCodes.length - 1);
      }
      const control = <FormArray>this.addchargesForm.controls['addChargesArray'];
      control.at(0).get('chargeId').setValue(chrg.id);
      control.at(0).get('chargeName').setValue(chrg.chargeName);
      control.at(0).get('displayToAppCode').setValue(displayTo);
      control.at(0).get('chargeTypeCode').setValue(chrg.chargeTypecode);
      control.at(0).get('chargeCode').setValue(chrg.chargeCode);
      control.at(0).get('freight').setValue(chrg.freight);
      control.at(0).get('includedInLC').setValue(chrg.includedInLC);
      control.at(0).get('inclProdCost').setValue(chrg.inclProdCost);
      control.at(0).get('applyOnModuleCode').setValue(applyOnModuleCodes);

    } else {
      let control: any
      control = <FormArray>this.addchargesForm.controls['addChargesArray'];
      index = 0
      this.clearControls();
    }
  }

  clearControls(){
    const control = <FormArray>this.addchargesForm.controls['addChargesArray'];
      control.at(0).get('chargeId').setValue('');
      control.at(0).get('chargeName').setValue('');
      control.at(0).get('displayToAppCode').setValue('');
      control.at(0).get('chargeTypeCode').setValue('');
      control.at(0).get('chargeCode').setValue('');
      control.at(0).get('freight').setValue('');
      control.at(0).get('includedInLC').setValue('');
      control.at(0).get('inclProdCost').setValue('');
      control.at(0).get('applyOnModuleCode').setValue('');
  }

  submitCharges() {
    if (!this.chargeFlag) {
      this.chargeFlag = true;
      let chargeValue;
      if (!this.chargeSetValidator()) {
        chargeValue = this.addchargesForm.get('addChargesArray').value;
        chargeValue[0].poId = this.poId;
        chargeValue[0].addedBy = this.addedBy;
        chargeValue[0].changedPartyBy = this.data[3];
        if (!this.isShowProrateToLine)
          chargeValue[0].poLineId = this.poLineId;
        if (this.isShowProrateToLine) {
          this.service.addUpdatePOHeaderCharges(chargeValue).subscribe(resp => {
            this.isSave = true;
            this.chargesOverlayContainer.submit();
          }, (err) => {
            this.chargeFlag = false;
            this.notify.show(err, 'error')
          })
        } else {
          this.service.addUpdatePOLinesCharges(chargeValue).subscribe(resp => {
            this.isSave = true;
            this.chargesOverlayContainer.submit();
          }, (err) => {
            this.chargeFlag = false;
            this.notify.show(err, 'error')
          })
        }
      }
    }
  }

  cancel() {
    if (this.isEditChargeExist > 0) {
      this.chargesOverlayContainer.addChargesFlag = false;
      this.chargesOverlayContainer.title = "CHARGE.LABEL.CHARGE_LIST";
      this.cdr.detectChanges();
    } else {
      this.chargesOverlayContainer.closeDialog();
    }

  }
}
