<div class="overlay-body" [formGroup]="addchargesForm">
    <!-- <div *ngIf="!isHideAddCharges" formArrayName="addChargesArray">
        <div fxLayout="column" fxFlex="100%;" class="mt-20" [formGroupName]="i"
            *ngFor="let order of addchargesForm.get('addChargesArray')['controls']; let i=index">
            <div fxLayout="row">
                <div fxLayout="column" fxFlex="30%;">
                    <div style="padding-top: 8px;" class="textLeft">
                        <span class="fieldLabel"> {{ 'CHARGE.LABEL.CHARGES' | translate }}*</span>
                    </div>
                </div>
                <div fxLayout="column" fxFlex="5%;">
                </div>
                <div fxLayout="column" fxFlex="65%;">
                    <mat-form-field class="formContainer">
                        <mat-select formControlName="name" (selectionChange)="setChargesValue($event.value)">
                            <mat-option value="">{{ 'TABLE_DISPLAY_COLUMNS.SELECT' | translate }}</mat-option>
                            <mat-option *ngFor="let charge of charges" [value]="charge.chargeCode">{{charge.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="order.controls['name'].hasError('required')">
                            {{ 'CHARGE.MESSAGE.CHARGE_NAME_REQUIRED_ERROR' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div fxLayout="row">
                <div fxLayout="column" fxFlex="30%;">
                    <div style="padding-top: 20px;" class="textLeft">
                        <span class="fieldLabel">{{ 'CHARGE.LABEL.CHARGE_AMOUNT_RATE' | translate }}
                            ({{'TABLE_DISPLAY_COLUMNS.SYMBOL' | pipes:'currancySymbol'}})*</span>
                    </div>
                </div>
                <div fxLayout="column" fxFlex="5%;">
                </div>
                <div fxLayout="column" fxFlex="65%;">
                    <mat-form-field>
                        <input matInput maxlength="64" placeholder="" formControlName="chargeAmt" fourdecimalPlace>
                        <mat-error *ngIf="order.controls['chargeAmt'].hasError('pattern') ">
                            {{ 'CHARGE.MESSAGE.CHARGE_AMOUNT_ERROR_VALID' | translate }}
                        </mat-error>
                        <mat-error
                            *ngIf="!order.controls['chargeAmt'].hasError('required') && !order.controls['chargeAmt'].hasError('max') && order.controls['chargeAmt'].hasError('min')">
                            {{ 'CHARGE.MESSAGE.CHARGE_AMOUNT_ZERO_ERROR' | translate }}
                        </mat-error>
                        <mat-error
                            *ngIf="!order.controls['chargeAmt'].hasError('required') && !order.controls['chargeAmt'].hasError('min') && order.controls['chargeAmt'].hasError('max')">
                            {{ 'CHARGE.MESSAGE.CHARGE_AMOUNT_BETWEEN_ERROR' | translate }}
                        </mat-error>
                        <mat-error
                            *ngIf="order.controls['chargeAmt'].hasError('required') && !order.controls['chargeAmt'].hasError('pattern') && !order.controls['chargeAmt'].hasError('min') && !order.controls['chargeAmt'].hasError('max')">
                            {{ 'CHARGE.MESSAGE.CHARGE_AMOUNT_ERROR' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div fxLayout="row">
                <div fxLayout="column" fxFlex="30%;">
                </div>
                <div fxLayout="column" fxFlex="5%;">
                </div>
                <div fxLayout="column" fxFlex="65%;">
                    <mat-radio-group formControlName="applyByCode">
                        <mat-radio-button value="FLAT">Flat</mat-radio-button>&nbsp;
                        <mat-radio-button value="PER_PACKTYPE">Per Packtype</mat-radio-button>&nbsp;
                        <mat-radio-button value="PER_WEIGHT">Per Weight (LB/Kg)</mat-radio-button>
                        <mat-error *ngIf="order.controls['applyByCode'].hasError('required')">
                            {{ 'CHARGE.MESSAGE.APPLY_BY_REQUIRED_ERROR' | translate }}
                        </mat-error>
                    </mat-radio-group>
                </div>
            </div>
            <div fxLayout="row">
                <div fxLayout="column" fxFlex="30%;">
                </div>
                <div fxLayout="column" fxFlex="5%;">
                </div>
                <div fxLayout="column" fxFlex="65%;">
                </div>
            </div>
            <div fxLayout="row" *ngIf="isShowProrateToLine" style="padding-top: 18px">
                <div fxLayout="column" fxFlex="30%;">
                    <div style="padding-top: 20px;" class="textLeft">
                        <span class="fieldLabel">{{ 'CHARGE.LABEL.PRORATE_TO_LINE_ITEM_BY' | translate }}*</span>
                    </div>
                </div>
                <div fxLayout="column" fxFlex="5%;">
                </div>
                <div fxLayout="column" fxFlex="65%;">
                    <mat-form-field class="formContainer">
                        <mat-select formControlName="prorateByCode">
                            <mat-option value="">{{ 'TABLE_DISPLAY_COLUMNS.SELECT' | translate }}</mat-option>
                            <mat-option *ngFor="let proratedBy of chargePRORATE_BY" [value]="proratedBy.code">
                                {{proratedBy.value}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="order.controls['prorateByCode'].hasError('required')">
                            {{ 'CHARGE.MESSAGE.PRORATED_BY_REQUIRED_ERROR' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div fxLayout="row" *ngIf="order.controls.inclProdCost.value">
                <div fxLayout="column" fxFlex="30%;">
                    <div style="padding-top: 20px;" class="textLeft">
                        <span class="fieldLabel">{{ 'CHARGE.LABEL.CHARGE_INCLUDED_IN_PC' | translate }}</span>
                    </div>
                </div>
                <div fxLayout="column" fxFlex="5%;">
                </div>
                <div fxLayout="column" fxFlex="65%;" style="padding-top: 20px;">
                    <span class="fieldLabel">{{'BUTTON.YES' | translate}}</span>
                </div>
            </div>
            <div fxLayout="row">
                <div fxLayout="column" fxFlex="30%;">
                    <div style="padding-top: 20px;" class="textLeft">
                        <span class="fieldLabel">{{ 'CHARGE.LABEL.DISPLAY_TO' | translate }}</span>
                    </div>
                </div>
                <div fxLayout="column" fxFlex="5%;">
                </div>
                <div fxLayout="column" fxFlex="65%;" style="padding-top: 20px;">
                    <span class="fieldLabel">{{order.controls.displayToAppCode.value}}</span>
                </div>
            </div>
            <div fxLayout="row">
                <div fxLayout="column" fxFlex="30%;">
                    <div style="padding-top: 20px;" class="textLeft">
                        <span class="fieldLabel">{{ 'CHARGE.LABEL.CHARGE_CODE_PLAIN' | translate }}</span>
                    </div>
                </div>
                <div fxLayout="column" fxFlex="5%;">
                </div>
                <div fxLayout="column" fxFlex="65%;" style="padding-top: 20px;">
                    <span class="fieldLabel">{{order.controls.chargeCode.value}}</span>
                </div>
            </div>
        </div>
    </div> -->
    <!-- (RD)PMB-7504 supplier order details UI change -->
    <div *ngIf="!isHideAddCharges" formArrayName="addChargesArray">
        <div class="chargeAddDetails" [formGroupName]="i"
            *ngFor="let order of addchargesForm.get('addChargesArray')['controls']; let i=index">
            <div class="chargeSection">
                <div class="leftSection">
                    <div class="inputfield">
                        <mat-form-field appearance="outline">
                            <mat-label>{{ 'CHARGE.LABEL.CHARGES' | translate }}<span>*</span></mat-label>
                            <mat-select placeholder="{{ 'CHARGE.LABEL.CHARGES' | translate }}" formControlName="name"
                                (selectionChange)="setChargesValue($event.value)" disableOptionCentering>
                                <mat-option value="">{{ 'TABLE_DISPLAY_COLUMNS.SELECT' | translate }}</mat-option>
                                <mat-option *ngFor="let charge of charges" [value]="charge.chargeCode" position="right"
                                    kendoTooltip title="{{charge.name}}">{{charge.name}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="order.controls['name'].hasError('required')">
                                {{ 'CHARGE.MESSAGE.CHARGE_NAME_REQUIRED_ERROR' | translate }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="amount-radio-row-section">
                        <div class="inputfield">
                            <mat-form-field appearance="outline">
                                <mat-label>Amount/Rate ($)<span>*</span></mat-label>
                                <input matInput maxlength="64" placeholder="Amount/Rate ($)*"
                                    prefix="{{ 'TABLE_DISPLAY_COLUMNS.SYMBOL'|  pipes:'currancySymbol'}}"
                                    mask="separator.4" thousandSeparator="," separatorLimit="9999" decimalMarker="."
                                    formControlName="chargeAmt">
                                <mat-error *ngIf="order.controls['chargeAmt'].hasError('pattern') ">
                                    {{ 'CHARGE.MESSAGE.CHARGE_AMOUNT_ERROR_VALID' | translate }}
                                </mat-error>
                                <mat-error
                                    *ngIf="!order.controls['chargeAmt'].hasError('required') && !order.controls['chargeAmt'].hasError('max') && order.controls['chargeAmt'].hasError('min')">
                                    {{ 'CHARGE.MESSAGE.CHARGE_AMOUNT_ZERO_ERROR' | translate }}
                                </mat-error>
                                <mat-error
                                    *ngIf="!order.controls['chargeAmt'].hasError('required') && !order.controls['chargeAmt'].hasError('min') && order.controls['chargeAmt'].hasError('max')">
                                    {{ 'CHARGE.MESSAGE.CHARGE_AMOUNT_BETWEEN_ERROR' | translate }}
                                </mat-error>
                                <mat-error
                                    *ngIf="order.controls['chargeAmt'].hasError('required') && !order.controls['chargeAmt'].hasError('pattern') && !order.controls['chargeAmt'].hasError('min') && !order.controls['chargeAmt'].hasError('max')">
                                    {{ 'CHARGE.MESSAGE.CHARGE_AMOUNT_ERROR' | translate }}
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="radio-section">
                            <mat-radio-group formControlName="applyByCode">
                                <mat-radio-button disableRipple value="FLAT">Flat</mat-radio-button>&nbsp;
                                <mat-radio-button disableRipple value="PER_PACKTYPE">Per Packtype</mat-radio-button>&nbsp;
                                <mat-radio-button disableRipple value="PER_WEIGHT">Per Weight (LB/Kg)</mat-radio-button>
                                <mat-error *ngIf="order.controls['applyByCode'].hasError('required')">
                                    {{ 'CHARGE.MESSAGE.APPLY_BY_REQUIRED_ERROR' | translate }}
                                </mat-error>
                            </mat-radio-group>
                        </div>

                    </div>



                    <div class="inputfield" *ngIf="isShowProrateToLine">
                        <mat-form-field appearance="outline">
                            <mat-label>{{ 'CHARGE.LABEL.PRORATE_TO_LINE_ITEM_BY' | translate
                                }}<span>*</span></mat-label>
                            <mat-select placeholder="{{ 'CHARGE.LABEL.PRORATE_TO_LINE_ITEM_BY' | translate }}*"
                                formControlName="prorateByCode">
                                <mat-option value="">{{ 'TABLE_DISPLAY_COLUMNS.SELECT' | translate }}</mat-option>
                                <mat-option *ngFor="let proratedBy of chargePRORATE_BY" [value]="proratedBy.code">
                                    {{proratedBy.value}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="order.controls['prorateByCode'].hasError('required')">
                                {{ 'CHARGE.MESSAGE.PRORATED_BY_REQUIRED_ERROR' | translate }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

            </div>
            <div class="displayToSection">
                <div class="displayLeftSection">
                    <div class="field-section">
                        <div class="fieldLabel">{{ 'CHARGE.LABEL.DISPLAY_TO' | translate }} </div>
                        <div class="fieldValue">{{order.controls.displayToAppCode.value}}</div>
                    </div>
                </div>
                <div class="displayRightSection">
                    <div class="field-section">
                        <div class="fieldLabel">{{ 'CHARGE.LABEL.CHARGE_CODE_PLAIN' | translate }} </div>
                        <div class="fieldValue">{{order.controls.chargeCode.value}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="isHideAddCharges" class="no-charge-permission">
        <span class="message-text">{{'CHARGE.MESSAGE.CHARGE_ADD_MESSAGE'|translate}}</span>
    </div>

</div>
<div mat-dialog-actions class="overlay-footer">
    <div class="left-section">
        <button id="cancel_CopyOverlay" (click)="cancel()"><span>{{'BUTTON.CANCEL'|translate}}</span></button>
    </div>
    <div class="right-section">
        <button [disabled]="poStatus || addchargesForm.invalid  || chargeFlag || dialogData?.isSplitPoFlagActive"
            (click)="submitCharges();"
            *ngIf="!auth?.user?.info?.internalAdmin && permissions && !isHideAddCharges"><span>{{'BUTTON.ADD_CHARGE'|translate}}</span></button>
    </div>
</div>
<!-- [ngClass]="{'disabledBtn': poStatus || addchargesForm.invalid  || chargeFlag || dialogData?.isSplitPoFlagActive}"  -->