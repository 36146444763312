export const Global = Object.freeze({
    BASE_URL : 'http://localhost:8080',
    
    LOGIN_API_URL: '/login',
    LOGOUT_API_URL: '/api/auth/logout',

    //To Navigate different app on local/production
    COMMON_APP_URL: 'http://localhost:4100/ca',
    BUYER_APP_URL: 'http://localhost:4200/ba',
    VENDOR_APP_URL: 'http://localhost:4300/va'
    
    
});