<div class="orderheader">
    <div class="title">
        <ng-content select="[title]">
        </ng-content>
    </div>
    <div class="field-section">
        <div>
            <ng-content select="[label]">
            </ng-content>
        </div>
        <div>
            <ng-content select="[value]">
            </ng-content>
        </div>
    </div>
    <div class="fieldError">
        <ng-content select="[error]">
        </ng-content>
    </div>
</div>