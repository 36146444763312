import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { NotificationService } from "@progress/kendo-angular-notification";
import { TranslateService } from "common-lib";

@Component({
  selector: 'app-push-notification-success',
  templateUrl: './push-notification-success.component.html',
  styleUrls: ['./push-notification-success.component.scss']
})
export class PushNotificationSuccessComponent implements OnInit {
  messages = [];
  title: string = 'Error';
  hideAfter = 5000;
  @ViewChild("template", { read: TemplateRef, static: true })
  public notificationTemplate: TemplateRef<any>;
  constructor(private notificationService: NotificationService, private translate: TranslateService) { }

  public show(msgs, duration = 1000): void {
    if (msgs != null || msgs != undefined) {
      this.messages = [];
      this.messages.push(msgs);
      this.notificationService.show({
        content: this.notificationTemplate,
        position: { horizontal: "right", vertical: "top" },
        animation: { type: "fade", duration: duration },
        closable: false,
        hideAfter: this.hideAfter,
        width: 350,
        type: { style: 'success', icon: false }
      });

    }
  }
  ngOnInit() {
  }

}
