import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { OneAuthService } from 'one-auth';
import { Global } from '../../../config';

@Injectable()
export class AuthGuard implements CanActivate {

	constructor(public auth: OneAuthService, private router: Router) { }

	canActivate(): boolean | Promise<boolean> {
		let token = this.auth.getToken();

		if (!token) {
			console.error("User is not authenticated.");
			this.redirectToLoginPage();
			return false;
		} else {
			return true;
		}
		// 	else if (this.authentication.isAuthenticated()) {
		// 		return true;
		// 	}
		// 	else {
		//   this.authentication.refreshToken();
		//   return true;
		// 	}
	}

	redirectToLoginPage() {
		window.location.href = Global.COMMON_APP_URL;
	}

}
