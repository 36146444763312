import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router, UrlSegment } from '@angular/router';
import { TranslateService, StorageService } from 'common-lib';

@Component({
  selector: 'content-infobar',
  templateUrl: './content-infobar.component.html',
  styleUrls: ['./content-infobar.component.scss']
})
export class ContentInfobarComponent implements OnInit {

  @Input()
  pageTitle: string;
  @Input()
  hidden: boolean = false;
  // This variable is use for hide/show breadcrumb
  @Input()
  isNewBreadcrumb: boolean = false;

  /* breadcrumbs: Array<any> = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .pipe(map(() => this.activatedRoute))
      .pipe(map((route) => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }))
      .pipe(filter(route => route.outlet === PRIMARY_OUTLET))
      .subscribe(route => {

        let snapshot = this.router.routerState.snapshot;
        this.breadcrumbs = [];
        let url = snapshot.url;
        let routeData = route.snapshot.data;

        console.log(routeData);
        let label = routeData['breadcrumb'];
        let params = snapshot.root.params;

        this.breadcrumbs.push({
          url: url,
          label: label,
          params: params
        });
        console.log(this.breadcrumbs);
      });

  }
} */

  public breadcrumbs: { name: string; url: string }[] = [];
  constructor(
    public router: Router, public translate: TranslateService,
    public activatedRoute: ActivatedRoute, private sessionStorage: StorageService) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.breadcrumbs = [];
        this.parseRoute(this.router.routerState.snapshot.root);
      }
    })
  }

  parseRoute(node: ActivatedRouteSnapshot) {
    if (node.data['breadcrumb']) {
      if (node.url.length) {
        let urlSegments: UrlSegment[] = [];
        node.pathFromRoot.forEach(routerState => {
          urlSegments = urlSegments.concat(routerState.url);
        });
        let url = urlSegments.map(urlSegment => {
          return urlSegment.path;
        }).join('/');
        if (url == 'order/order-list') {
          url = '/order/order-list'
        }
        this.pageTitle = node.data['breadcrumb']
        this.breadcrumbs.push({
          name: node.data['breadcrumb'],
          url: '/' + url
        })
        this.pageTitle = '';
        this.pageTitle = this.translate.instant(node.data['breadcrumb']);
      }
    }
    if (node.firstChild) {
      this.parseRoute(node.firstChild);
    }
  }

  setBreadCrum(node, url) {
    this.breadcrumbs.push({
      name: node.data['breadcrumb'],
      url: '/' + url
    })
  }

  ngOnInit() {

  }

  // This function is use for move to previus page (Date: 08-01-2023)
  seeClickedurl(url){
    if(url!='/demands/recievedList/demand-details'){
      this.sessionStorage.remove('nextToPageName');
    }
    this.router.navigate([url]);
  }

}
