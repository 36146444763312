import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { URLS } from "@procurant/env/config.url";
import { PopupRef } from '@progress/kendo-angular-popup';
import { SUB_STATUS, TranslateService } from 'common-lib';
import { OneNotifyService } from 'one-auth';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})


export class OrderService {

  public headers: HttpHeaders;
  SUBSTATUS = SUB_STATUS;
  productLines: any[] = [];
  isBioProductWarning: boolean = false;

  myViewData: any;
  myViewTittle: any;
  public popupRef: PopupRef;

  constructor(private http: HttpClient, private transalate: TranslateService, public notify: OneNotifyService) {
    this.headers = new HttpHeaders();
    this.headers.set('Content-Type', 'application/json');
  }

  getOrders(requestData): Observable<any> {
    return this.http.post<any>(URLS.GetOrdersAPI, requestData, { headers: this.headers }).pipe(
      map((res: Response) => {
        return res;
      }));
  }

  getShipFromLocations(supplierId: any): Observable<any> {
    let url = URLS.ShipsFromBusinessUnitAPI + "SHIP_FROM";
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      }));
  }

  searchOrderNumber(orderNumber: any): Observable<any> {
    let url = URLS.SearchOrderNumber + orderNumber;
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
        // return this.extractData(res);
      }));
  }

  searchOrder(orderNumber: any): Observable<any> {
    let url = URLS.SearchOrder + orderNumber;
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      }));
  }

  searchOrderByFilter(type: any, input: any): Observable<any> {
    let url = URLS.SearchOrderByFiltersAPI + type + '?input=' + input;
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      })
    );
  }

  updateOrder(data): Observable<any> {
    return this.http.put<any>(URLS.UpdateOrder, data, { headers: this.headers }).pipe(
      map((res) => {
        return res;
      }));
  }

  getOrderDetails(id): Observable<any> {
    let url = URLS.GetOrderDetails + id;
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      }));
  }

  checkParellelTransaction(id, versioning) {
    let url = URLS.CheckParellelTransactions + id + '/' + versioning;
    return this.http.get<any>(url)
      .pipe(map((res) => {
        return res;
      }));
  }

  getProduct(productId): Observable<any> {
    let url = URLS.GetProduct + productId;
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      }
      ));
  }

  getMappedProduct(request): Observable<any> {
    return this.http.post<any>(URLS.GetMappedProduct, request, { headers: this.headers }).pipe(
      map((res) => {
        return res;
      }
      ));
  }

  getCountryList(): Observable<any> {
    return this.http.get(URLS.GetCountryList).pipe(
      map((res) => {
        return res;
      }));
  }

  // updateTraceInfo(data): Observable<any> {
  //   return this.http.put<any>(URLS.UpdateTraceInfo, data, { headers: this.headers, observe: 'response' }).pipe(
  //     map((res) => {
  //       return res;
  //     }));
  // }

  isSONumberExists(soNumber): Observable<any> {
    let url = URLS.SONumberExistsAPI + soNumber;
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      })
    )
  }

  updateLineProduct(data): Observable<any> {
    return this.http.put<any>(URLS.UpdateLineProduct, data, { headers: this.headers }).pipe(
      map((res) => {
        return res;
      }));
  }

  getInvoiceInfo(p) {
    return this.http.get<any>(URLS.GetInvoiceFormPoId + p).pipe(
      map((res) => {
        return res;
      }));
  }

  getpaymentTerms(): Observable<any> {
    return this.http.get<any>(URLS.GetPaymentTerms + "/true").pipe(
      map((res) => {
        return res;
      }));
  }

  extractData(response) {
    return response.body;
  }

  isExist(key, value): Observable<any> {
    let url = "";
    switch (key) {
      case 'code':
        url = URLS.CatalogCodeExists + value;
        return this.http.get<any>(url).pipe(
          map((res) => {
            return res;
          }));
      // return isCodeExists
      case 'gtin':
        let gtinRequest = { gtin: value };
        // var isGTINExists = this.$http.baseUrlContext.url('/catalog/api/catalog/check-if-gtin-exist').put(gtinRequest)
        return this.http.put<any>(URLS.GTINExists, gtinRequest, { headers: this.headers }).pipe(
          map((res) => {
            return res;
            // return this.extractData(res);
          }));
      // return isGTINExists;
      case 'upc':
        // var isUPSCExists = this.$http.baseUrlContext.url('/catalog/api/catalog/check-upc-exist/' + value).get()
        url = URLS.UPCExists + value;
        return this.http.get<any>(url).pipe(
          map((res) => {
            return res;
            // return this.extractData(res);
          }));
      // return isUPSCExists;;
      default:
        return null;
    }
  }
  getLoadData(id): Observable<any> {
    let url = URLS.GetLoadData + id;
    return this.http.get<any>(url, { observe: 'response' }).pipe(
      map((res) => {
        return res;
      }));
  }

  getSubStatusIcon(subStatus: string, isIcon: boolean = true): string {
    let subStatusIcon: string;
    let subStatusTooltip: string;
    switch (subStatus) {
      case this.SUBSTATUS.BUYER_MODIFIED:
        subStatusIcon = 'R';
        subStatusTooltip = this.SUBSTATUS.BUYER_MODIFIED;
        break;
      case this.SUBSTATUS.BUYER_RECONCILED:
        subStatusIcon = 'R';
        subStatusTooltip = this.SUBSTATUS.BUYER_RECONCILED;
        break;
      case this.SUBSTATUS.SUPPLIER_MODIFIED:
        subStatusIcon = 'B';
        subStatusTooltip = this.SUBSTATUS.SUPPLIER_MODIFIED;
        break;
      case this.SUBSTATUS.SUPPLIER_RECONCILED:
        subStatusIcon = 'B';
        subStatusTooltip = this.SUBSTATUS.SUPPLIER_RECONCILED;
        break;
      case this.SUBSTATUS.BUYER_SUPPLIER_MODIFIED:
        subStatusIcon = 'R';
        subStatusTooltip = this.SUBSTATUS.BUYER_SUPPLIER_MODIFIED;
        break;
      case this.SUBSTATUS.BUYER_CANCELLED:
        subStatusIcon = 'R';
        subStatusTooltip = this.SUBSTATUS.BUYER_CANCELLED;
        break;
      case this.SUBSTATUS.SUPPLIER_CANCELLED:
        subStatusIcon = 'B';
        subStatusTooltip = this.SUBSTATUS.SUPPLIER_CANCELLED;
        break;
      default:
        subStatusIcon = ''
        subStatusTooltip = '';
        break;
    }
    if (isIcon)
      return subStatusIcon;
    else
      return subStatusTooltip;
  }


  /*********************************** ORDER HISTORY CALLS STARTS **************************************/

  getOrderHistory(request, orgId): Observable<any> {
    return this.http.post<any>(URLS.GetOrderHistory + orgId, request, { headers: this.headers }).pipe(
      map((res) => {
        return res;
      }));
  }

  getPOLineHistory(orgId, queryParam): Observable<any> {
    let url = URLS.GetPoLineHistory + orgId + queryParam;
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      })
    );
  }

  /*********************************** ORDER HISTORY CALLS ENDS **************************************/

  getFileObjectDocument(objId): Observable<any> {
    let url = URLS.downloadFile + objId;
    return this.http.get(url, { headers: this.headers, observe: 'response', responseType: 'blob' }).pipe(
      map((res) => {
        return res;
      }));
  }

  DeletePODocument(id) {
    let url = URLS.GetPoLineHistory + id;
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      })
    );
  }

  FetchDocument(id) {
    let url = URLS.GetPoLineHistory + id;
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      })
    );
  }

  /* Order Documents */
  uploadPODoc(id, data, levelOfUpload) {
    let headers = new HttpHeaders();
    // Add Content-Type be default
    headers.set('Content-Type', 'application/json');
    let url = ''
    if (levelOfUpload == "LINE_LEVEL") {
      url = URLS.POLineFileUpload + id
    } else {
      url = URLS.POFileUpload + id
    }
    return this.http.post(url, data, {
      reportProgress: true,
      observe: 'events',
      headers: this.headers,
      responseType: 'text'
    }).pipe(map((event) => {
      switch (event.type) {
        case HttpEventType.UploadProgress:
          const progress = Math.round(100 * event.loaded / event.total);
          return { status: 'progress', message: progress };
        case HttpEventType.Response: {
          this.notify.show(this.transalate.instant('COMPANY.MESSAGE.DCO_UPLOAD_SUCCESS'));
          return event.body;
        }
        default:
          return `Unhandled event: ${event.type}`;
      }
    })
    );
  }

  FetchPODocument(id, levelOfUpload, poId?) {
    let url = ''
    if (levelOfUpload == "LINE_LEVEL") {
      url = URLS.FetchPOLineLevelFiles + id + "/" + poId
    } else {
      url = URLS.FetchPOLevelFiles + id
    }
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      })
    );
  }

  deletePODocument(id, sourceId?, sourceType?) {
    let url = URLS.DeletePODocument + id + '&sourceId=' + sourceId + '&sourceType=' + sourceType
    return this.http.delete(url, { headers: this.headers, responseType: 'text' })
  }

  getFileObject(objId): Observable<any> {
    let url = URLS.GetPOFileObjectViewAPI + objId;
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      }));
  }

  deleteFromWatchList(...para) {
    let url = URLS.DeleteFromWatchList + 'userId=' + para[0] + '&poId=' + para[1];
    return this.http.delete(url, { headers: this.headers, responseType: 'text' })
  }

  addToWatchList(reqData) {
    let url = URLS.AddToWatchList;
    return this.http.post(url, reqData, { headers: this.headers, responseType: 'text' })
  }

  getBuyerList() {
    return this.http.get(URLS.GetBuyerList, { headers: this.headers })
  }

  getShipFroms() {
    return this.http.get(URLS.GetShipFromList, { headers: this.headers })
  }

  getDeliveryLocations(): Observable<any> {
    return this.http.get<any>(URLS.GetDeliveryToAPI + "SUPPLIER/BUYER/DELIVERY").pipe(
      map((res) => {
        return res;
      }
      ));
  }

  getStatus(): Observable<any> {
    let url = URLS.GetOrderStatus;
    return this.http.get(url).pipe(
      map((res) => {
        return res;
      }));
  }

  getCommodities(): Observable<any> {
    let url = URLS.GetCommodities;
    return this.http.get(url).pipe(
      map((res) => {
        return res;
      }));
  }

  getFilterList(appCode, moduleCode, category, subCategory): Observable<any> {
    let url = URLS.GetFilterListAPI + appCode + '/' + moduleCode + '/' + category + '/' + subCategory;
    return this.http.get(url).pipe(
      map((res) => {
        return res;
      }));
  }

  saveFilter(data): Observable<any> {
    return this.http.post(URLS.SaveFilterAPI, data, { headers: this.headers, observe: 'response', responseType: 'text' }).pipe(
      map((res) => {
        return res;
      }));
  }

  deleteFilter(id) {
    let url = URLS.DeleteFilterAPI + '?myViewId=' + id;
    return this.http.delete(url, { headers: this.headers, responseType: 'text' }).pipe(
      map(res => {
        return res;
      }));
  }

  searchMappedProductCode(pricePer: any, partnerCatalogItemId: any, partnerRole: any, partnerOrganizationId: any, catalogItemId: any, mapProduct: boolean): Observable<any> {
    let url = URLS.SuggestsubstitueProductCodeAPI + pricePer + '/' + partnerCatalogItemId + '/' + partnerRole + '/' + partnerOrganizationId + '/' + catalogItemId + '/' + mapProduct;
    return this.http.get(url).pipe(
      map((res) => {
        return res;
      })
    );
  }

  createSubstituteProduct(data): Observable<any> {
    return this.http.put(URLS.CreateSubstitueProduct, data, { headers: this.headers, observe: 'response' }).pipe(
      map((res) => {
        return res;
      }));
  }

  setIndicatorAsViwed(reqData) {
    let url = URLS.setIndicatorAsViwedAPI;
    return this.http.post(url, reqData, { headers: this.headers, responseType: 'text' })
  }

  getBRRules(data): Observable<any> {
    return this.http.post<any>(URLS.BRRules, data, { headers: this.headers }).pipe(
      map((res) => {
        return res;
      }));
  }

  getListOfComments(docTypeCode, docId): Observable<any> {
    // return this.$http.baseUrlContext.url("/" + this.getApiCallUrl(docTypeCode) + "/comment/list/" + docId + "/" + docTypeCode).get()
    //   .pipe(map(res => this.extractData(res)));
    let url = URLS.COMMENTS + "/comment/list/" + docId + "/" + docTypeCode;
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      })
    );
  }


  getBuyerUser(buyerId): Observable<any> {
    let url = URLS.GetBuyerUser + buyerId;
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getLotCode(data): Observable<any> {
    return this.http.post<any>(URLS.GetLotCode, data, { headers: this.headers }).pipe(
      map((res) => {
        return res;
      }));
  }

  getSubscribedApplications(): Observable<any> {
    return this.http.get<any>(URLS.GetSubscribedApplicationsAPI).pipe(
      map((res) => {
        return res;
      }));
  }

  getTraceInfo(orderId): Observable<any> {
    return this.http.get<any>(URLS.GetTraceInfo + orderId).pipe(
      map((res) => {
        return res;
      }));
  }

  updateTraceInfo(data): Observable<any> {
    return this.http.put<any>(URLS.UpdateTraceInfo, data, { headers: this.headers }).pipe(
      map((res) => {
        return res;
      }));
  }

  //new API implememtation
  updateOrderNew(data): Observable<any> {
    return this.http.put<any>(URLS.UpdateOrderNewAPI, data, { headers: this.headers }).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getHeaderDetails(id): Observable<any> {
    let url = URLS.GetPOOrderHeaderDetails + id;
    return this.http.get<any>(url)
      .pipe(map((res) => {
        return res;
      }));
    // return listOfStates;
  }

  getHeaderIndicators(id): Observable<any> {
    let url = URLS.GetPOHeaderIndicators + id;
    return this.http.get<any>(url)
      .pipe(map((res) => {
        return res;
      }));
    // return listOfStates;
  }

  getLineSummary(id): Observable<any> {
    let url = URLS.GetPOLineSummary + id;
    return this.http.get<any>(url)
      .pipe(map((res) => {
        return res;
      }));
  }


  getPOSummary(id): Observable<any> {
    let url = URLS.GetPOOrderHeaderDetails + id;
    return this.http.get<any>(url)
      .pipe(map((res) => {
        return res;
      }));
  }

  getLineDetails(id): Observable<any> {
    let url = URLS.GetPOOrderLineDetails + id;
    return this.http.get<any>(url)
      .pipe(map((res) => {
        return res;
      }));
  }

  getLineDetailsPaging(req): Observable<any> {

    let url = URLS.GetPOOrderLineDetailsPaging;
    return this.http.post<any>(URLS.GetPOOrderLineDetailsPaging, req, { headers: this.headers })
      .pipe(map((res) => {
        return res;
      }));

  }

  getHeaderCharges(id): Observable<any> {

    let url = URLS.POHeaderCharges + id;
    return this.http.get<any>(url)
      .pipe(map((res) => {
        return res;
      }));

  }
  /* getLineCharges(id): Observable<any> {

    let url = URLS.POLineCharges + id;
    return this.http.get<any>(url)
      .pipe(map((res) => {
        return res;
      }));

  } */

  getLineCharges(poId?, poLineId?): Observable<any> {

    let url = URLS.POLineCharges + poId + "/" + poLineId;
    return this.http.get<any>(url)
      .pipe(map((res) => {
        return res;
      }));

  }

  postAddLine(data): Observable<any> {
    return this.http.post<any>(URLS.SupplierAddLineAPI, data, { headers: this.headers }).pipe(
      map((res) => {
        return res;
      })
    );
  }

  searchProductCode(code: any): Observable<any> {
    let url = URLS.SearchProductCodeAPI + code;
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      })
    );
  }

  searchProductCodeOrgId(code, orgId): Observable<any> {
    let url = URLS.SearchProductCodeAPI + code + "/" + orgId;
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getProductDetailsService(code, orgId?): Observable<any> {
    let url = URLS.GetProductCodeDetailsAPI + code;
    if (orgId != null) {
      url = url + "/" + orgId;
    }
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      })
    );
  }


  getPartnerMappedProductDetails(productId, appCode, partnerOrganizationId): Observable<any> {
    let url = URLS.GetPartnerMappedProductDetailsAPI + productId + "/" + appCode + "/" + partnerOrganizationId;
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getOrgsListDetails(orgIds: number[] = []) {
    let url = URLS.GetOrgsDetails + "?ids=" + orgIds
    return this.http.get<any>(url, { headers: this.headers })
      .pipe(map((res) => {
        return res;
      }));
  }

  cancelledPOMarkedAsViewed(requestData): Observable<any> {
    return this.http.post(URLS.CancelledPOMarkedAsViewedAPI, requestData, { headers: this.headers, responseType: 'text' })
  }

  getGrowingList(): Observable<any[]> {
    return this.http.get<any>(URLS.FetchGrowingLotListAPI).pipe(
      map((res) => {
        return res;
      }));
  }

  //get freight rate service 
  public getFreightRate(freightRateRequest: any): Observable<any> {
    return this.http.post<any>(URLS.GetFreightRateAPI, freightRateRequest)
  }

  getLoadDataTransplace(id): Observable<any> {
    let url = URLS.GetLoadDataTransplace + id;
    return this.http.get<any>(url, { observe: 'response' }).pipe(
      map((res) => {
        return res;
      }));
  }

  public getSplitOrder(poId): Observable<any> {
    return this.http.put<any>(URLS.GetSplitOrderAPI + "?poId=" + poId, null);
  }

  getLatestComment(id): Observable<any> {
    return this.http.get<any>(URLS.GetLatestCommentAPI + '/false/PO/' + id).pipe(
      map((res) => {
        return res;
      }
      ));
  }
  
  growingMethod(data): Observable<any> {
    return this.http.post<any>(URLS.GrowingMethod, data, { headers: this.headers }).pipe(
      map((res) => {
        return res;
      }));
  }

}