export const IMAGES = Object();

IMAGES.PROCURANT_LOGO = "assets/images/procurant_logo.png";
IMAGES.PRODUCTCODE_CLOSE_IMG = "assets/images/list-btn.svg"
IMAGES.PRODUCTCODE_OPEN_IMG = "assets/images/task_expand.svg"
IMAGES.PRODUCTCODE_DELETE_IMG = "assets/images/Trash_light.svg"
IMAGES.FILTERVIEW_CLOSE_IMG = "assets/images/Filter_alt_light.svg";
IMAGES.FILTERVIEW_OPEN_IMG = "assets/images/Filter_alt_light_expand.svg";
IMAGES.ACTION_HEADER_HORIZONTAL_DOT_CLOSE_IMG = "assets/images/dots-horizontal.svg";
IMAGES.ACTION_HEADER_HORIZONTAL_DOT_OPEN_IMG = "assets/images/dots-horizontal_expand.svg";
IMAGES.ACTION_HEADER_PRINT_IMG = "assets/images/print_light.svg";
IMAGES.ACTION_HEADER_HISTORY_IMG = "assets/images/history-icon.svg";
IMAGES.ACTION_HEADER_NEW_CHARGES_IMG = "assets/images/new-charges.svg";
IMAGES.ACTION_HEADER_OLD_CHARGES_IMG = "assets/images/coins-stacked-01.svg";
IMAGES.ACTION_HEADER_CHARGES_IMG = "assets/images/coins-stacked.svg";
IMAGES.ACTION_HEADER_NEW_ATTACH_IMG = "assets/images/new-attachment.svg";
IMAGES.ACTION_HEADER_OLD_ATTACH_IMG = "assets/images/old-attachment.svg";
IMAGES.ACTION_HEADER_ATTACH_IMG = "assets/images/Attachment.svg";
IMAGES.ACTION_LINE_CANCEL_PO_IMG = "assets/images/po-line-cancel.svg";
IMAGES.ACTION_LINE_SUBSTITUTE_IMG = "assets/images/substitute-new.svg";
IMAGES.ACTION_LINE_SUBSTITUTE_DISABLE_IMG = "assets/images/substitute-new-disable.svg";
IMAGES.ACTION_LINE_SUBSTITUTE_BUYER_IMG = "assets/images/substitute_buyer.svg";
IMAGES.ACTION_LINE_CANCEL_PO_DISABLED_CIRCLE = "assets/images/disabled-circle.svg";
IMAGES.FILTERVIEW_CLOSE_NEW_IMG = "assets/images/view-customization-close.svg";
IMAGES.FILTERVIEW_OPEN_NEW_IMG = "assets/images/view-customization.svg";
IMAGES.QUICK_ORDER_LIST = "assets/images/file_dock_light.svg";
IMAGES.QUICK_ORDER_LIST_ACTIVE = "assets/images/file_order_list.svg";
IMAGES.NEXT = "assets/images/chevron-right.svg";
IMAGES.PREV = "assets/images/chevron-left.svg";
IMAGES.SERACH = "assets/images/search-md.svg";
IMAGES.HISTORY = "assets/images/history-icon.svg";
IMAGES.BLANK_COMMENT = "assets/images/blank_comment.svg";
IMAGES.READ_COMMENT = "assets/images/read_comment.svg";
IMAGES.UNREAD_COMMNET = "assets/images/unread_commnet.svg";
IMAGES.FLAG_WATCH = "assets/images/flag_watch.svg";
IMAGES.UNFLAG_WATCH = "assets/images/unflag_watch.svg";
IMAGES.ADD_LINE = "assets/images/add-line.svg";
IMAGES.TRACE_INFO = "assets/images/add_trace_info_img.svg";
IMAGES.DISPLAY_AS_ORGANIC = "assets/images/display_as_organic_img.svg";
IMAGES.SPLIT_PO_LINES = "assets/images/split_po_lines_img.svg";
IMAGES.CANCELLINE = "assets/images/Cancel.svg";
IMAGES.CANCEL_ORDER = "assets/images/order-cancel.svg";
IMAGES.POPOVER_PERSON = "assets/images/sup-person.svg";
IMAGES.POPOVER_LOCATION = "assets/images/sup-location-on.svg";
IMAGES.POPOVER_EMAIL = "assets/images/sup-email.svg";
IMAGES.POPOVER_LOCAL_PHONE = "assets/images/sup-local-phone.svg";
IMAGES.NEW_POLINE = "assets/images/new-po-line.svg";
IMAGES.ORGANIC_ICON = "assets/images/Organic-Icon.svg"

