import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { CommonLibModule, HttpService, TRANSLATION_PROVIDERS } from 'common-lib';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Router } from '@angular/router';
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { SharedModule } from './modules/shared-components/shared-components.module';
// import { IGlobals } from './modules/shared-components/services/globals.service';
import { AuthGuard } from './modules/shared-components/services/auth.guard';
import { ProductEditComponent } from './modules/purchase-order/components/product-edit-overlay/product-edit.component';
import { environment } from '@procurant/env/environment';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { CallChargesOverlayComponent } from './modules/purchase-order/components/charges-overlay/call-charges-overlay.component';
import { OneAuthService, OneAuthModule } from 'one-auth';
import { AppRoutingModule } from './app-routing.module';
import { DevLoginModule } from './modules/dev-login/dev-login.module';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { CanDeactivateGuard, RedirectGuard } from './services/redirect-guard';
import { AppToolbarsModule } from './modules/app-toolbars/app-toolbars.module';
import { HttpRequestInterceptor } from './services/http.interceptor';
import { InitLoaderService } from './services/init-loader.service';
import { LoadingService } from '@procurant/sharedcomponent/services/loading.service';
import { CatalogProductEditComponent } from './modules/product-catalog/components/catalog-product-edit-overlay/catalog-product-edit.component';

/* export function httpServiceFactory(http: HttpClient): HttpService {
  return new HttpService(http, new IGlobals());
}

export let HttpServiceProvider =
{
  provide: HttpService,
  useFactory: httpServiceFactory,
  deps: [HttpClient]
}; */

@NgModule({
    declarations: [
        AppComponent,
        ProductEditComponent,
        CallChargesOverlayComponent,
        CatalogProductEditComponent
    ],
    imports: [
        BrowserModule,
        CommonLibModule.forRoot(environment),
        BrowserAnimationsModule,
        HttpClientModule,
        SharedModule,
        AppRoutingModule,
        InfiniteScrollModule,
        InputsModule,
        DevLoginModule,
        AppToolbarsModule,
        OneAuthModule.forRoot(environment)
    ],
    exports: [],
    providers: [CurrencyPipe, DecimalPipe, AuthGuard, TRANSLATION_PROVIDERS,
        OneAuthService,
        InitLoaderService,
        HttpRequestInterceptor,
        {
            provide: MAT_DIALOG_DEFAULT_OPTIONS,
            useValue: {
                hasBackdrop: true
            }
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpRequestInterceptor,
            multi: true
        },
        RedirectGuard,
        LoadingService,
        CanDeactivateGuard
        //HttpServiceProvider, { provide: 'BASE_API_URL', useClass: IGlobals }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }