<div fxLayout="row">
    <div fxLayout="column">
        <h2 mat-dialog-title>{{data.title | translate}}</h2>
    </div>
    <span style="flex: 1 1 auto;"> </span>
    <div fxLayout="column">
        <a href="javascript:void(0);" style="min-width: unset;" (click)="onNoClick()">
            <mat-icon>close</mat-icon>
        </a>
    </div>
</div>
<mat-dialog-content>
    <ng-template #target></ng-template>
</mat-dialog-content>