<side-nav>
  <div class="navBarHeight" sidenavitem>
    <!-- (RD)PMB-6554 New header and navigation menu bar for Procurant Commerce -->
    <div class="nav-new-bar">
      <div *ngIf="navigationScrollButton=='up' || navigationScrollButton=='both'" class="up-btn"
        (click)="handelScrollClick('up')">
        <img src="../../../../../assets/images/up_double.png" alt="">
      </div>
      <div class="nav-menu-list" id="sidebar" (scroll)="scrollFunction()">
        <a mat-list-item routerLink="/dashboard" routerLinkActive="active"
          title="{{'NAVBAR.LABEL.DASHBOARD' | translate}}">
          <!-- <span class="sidebar-icon icon-dashboard"></span> -->
          <div class="sprite-container dashboard">
            <div class="sprite-image"></div>
          </div>
          <span class="nav-name">{{ 'NAVBAR.LABEL.DASHBOARD' | translate }}</span>
        </a>
        <a mat-list-item routerLink="/task-center/task-center-list" routerLinkActive="active"
          title="{{'NAVBAR.LABEL.TASK_CENTER' | translate}}">
          <!-- <span class="sidebar-icon icon-task_centre"></span> -->
          <div class="sprite-container taskcenter">
            <div class="sprite-image"></div>
          </div>
          <span class="nav-name">{{ 'NAVBAR.LABEL.TASK_CENTER' | translate }}</span>
        </a>
        <a mat-list-item *ngIf="permissions?.SUPPLIER?.MODULES?.PRICING?.actions?.view"
          routerLink="/product-pricing/pricing-sheet-list" routerLinkActive="active"
          title="{{'NAVBAR.LABEL.PRICING' | translate}}">
          <!-- <span class="sidebar-icon icon-pricing"></span> -->
          <div class="sprite-container pricing">
            <div class="sprite-image"></div>
          </div>
          <span class="nav-name"> {{ 'NAVBAR.LABEL.PRICING' | translate }}</span>
        </a>
        <a mat-list-item *ngIf="permissions?.SUPPLIER?.MODULES?.SALES_ORDER?.actions?.view"
          routerLink="/order/order-list" routerLinkActive="active" title="{{'NAVBAR.LABEL.ORDERS' | translate}}">
          <!-- <span class="sidebar-icon icon-orders_list"></span> -->
          <div class="sprite-container order">
            <div class="sprite-image"></div>
          </div>
          <span class="nav-name">{{ 'NAVBAR.LABEL.ORDERS' | translate }}</span>
        </a>
        <a mat-list-item *ngIf="permissions?.SUPPLIER?.MODULES?.INVOICES?.actions?.edit"
          routerLink="/invoice/invoice-list" routerLinkActive="active" title="{{'NAVBAR.LABEL.INVOICE' | translate}}">
          <!-- <span class="sidebar-icon icon2-invoice"></span> -->
          <div class="sprite-container invoice">
            <div class="sprite-image"></div>
          </div>
          <span class="nav-name">{{ 'NAVBAR.LABEL.INVOICE' | translate }}</span>
        </a>
        <a mat-list-item *ngIf="permissions?.SUPPLIER?.MODULES?.CROSS_DOCK?.actions?.view"
          routerLink="/cd-order/cd-order-list" routerLinkActive="active"
          title="{{'NAVBAR.LABEL.CROSS_DOCK' | translate}}">
          <!-- <mat-icon class="vAlignTop green">local_shipping</mat-icon> -->
          <div class="sprite-container cross-dock">
            <div class="sprite-image"></div>
          </div>
          <span class="nav-name">{{ 'NAVBAR.LABEL.CROSS_DOCK' | translate }}</span>
        </a>
        <a mat-list-item routerLink="/catalog" routerLinkActive="active"
          *ngIf="permissions?.SUPPLIER?.MODULES?.CATALOG?.actions?.view" title="{{'NAVBAR.LABEL.CATALOG' | translate}}">
          <!-- <span class="sidebar-icon icon-catalog"></span> -->
          <div class="sprite-container catalog">
            <div class="sprite-image"></div>
          </div>
          <span class="nav-name">{{ 'NAVBAR.LABEL.CATALOG' | translate }}</span>
        </a>
        <a mat-list-item routerLink="/origin" *ngIf="permissions?.SUPPLIER?.MODULES?.CATALOG?.actions?.view"
          routerLinkActive="active" title="{{'NAVBAR.LABEL.ORIGIN' | translate}}">
          <!-- <span class="sidebar-icon icon-earth"></span> -->
          <div class="sprite-container origin">
            <div class="sprite-image"></div>
          </div>
          <span class="nav-name">{{ 'NAVBAR.LABEL.ORIGIN' | translate }}</span>
        </a>
        <a mat-list-item routerLink="/reports-analytics" routerLinkActive="active"
          title="{{'NAVBAR.LABEL.REPORTS' | translate}}">
          <!-- <span class="sidebar-icon icon-analytics"></span> -->
          <div class="sprite-container report">
            <div class="sprite-image"></div>
          </div>
          <span class="nav-name">{{ 'NAVBAR.LABEL.REPORTS' | translate }}</span>
        </a>
        <a mat-list-item routerLink="/my-preferences" routerLinkActive="active"
          title="{{'NAVBAR.LABEL.MY_PREFERENCES' | translate}}">
          <!-- <span class="sidebar-icon icon2-settings"></span> -->
          <div class="sprite-container preferences">
            <div class="sprite-image"></div>
          </div>
          <span class="nav-name">{{ 'NAVBAR.LABEL.MY_PREFERENCES' | translate }}</span>
        </a>
        <a *ngIf="false" mat-list-item routerLink="/track-trace" routerLinkActive="active"
          title="{{'NAVBAR.LABEL.TRACK_TRACE' | translate}}">
          <!-- <span class="sidebar-icon icon-track-trace"></span> -->
          <div class="sprite-container trace">
            <div class="sprite-image"></div>
          </div>
          <span class="nav-name">{{'NAVBAR.LABEL.TRACK_TRACE' | translate}}</span>
        </a>
      </div>
      <div *ngIf="navigationScrollButton=='down' || navigationScrollButton=='both'" class="down-btn"
        (click)="handelScrollClick('down')">
        <img src="../../../../../assets/images/down_double.png" alt="">
      </div>
    </div>

    <div class="connect-link">
      <a mat-list-item routerLink="/connect" target=”_blank” routerLinkActive="active" title="{{'NAVBAR.LABEL.CONNECT' | translate}}">
        <span class="sidebar-icon icon-Group"></span>
        <span class="nav-name">{{'NAVBAR.LABEL.CONNECT' | translate}}</span>
      </a>
    </div>
  </div>
</side-nav>