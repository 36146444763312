import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from 'common-lib';
import { OneAuthService } from 'one-auth';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { InitLoaderService } from './services/init-loader.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
  // template: '<router-outlet></router-outlet><ng4-loading-spinner > </ng4-loading-spinner>'
})

export class AppComponent {
  pageTitle: string = 'PAGE_TITLES.SUPPLIER';
  title = 'PAGE_TITLES.SUPPLIER';
  isThemeDark: Observable<boolean>;
  private page: string;
  public isLogin: boolean = false;
  public isAppSelector = false;
  constructor(private init: InitLoaderService, private auth: OneAuthService, private titleService: Title,
    public route: Router,
    public router: ActivatedRoute, private translate: TranslateService) {
    //this.translate.setDefaultLang('en_US');
    this.init.load();   
  }
  ngOnInit() {


    // if (this.auth.user.info.id === undefined) {
    //   this.auth.LoadAuth().then((r) => {
    //     console.log(r);
    //   });
    // }

    this.route.events.subscribe((pat) => {
      if (this.route.url === "/BBF65054CC9C2C8CB6692B2A1648FD8C" || this.route.url === "/logout") {
        if (!this.isLogin) {
          this.isLogin = true;
        }
      } else if (this.route.url !== "/BBF65054CC9C2C8CB6692B2A1648FD8C" && this.route.url !== "/logout") {
        if (this.isLogin) {
          this.isLogin = false;
        }
      }
    });


    this.route.events.pipe(filter((event: any) => event instanceof NavigationEnd)).subscribe(() => {
      var root = this.route.routerState.snapshot.root;
      while (root) {
        if (root.children && root.children.length) {
          root = root.children[0];
        } else if (root.data && root.data["title"]) {
          this.pageTitle = root.data["title"];
          this.pageTitle = this.translate.instant(this.pageTitle);
          this.titleService.setTitle(this.pageTitle)
          return;
        } else {
          return;
        }
      }
    });
  }

}
