import { DOCUMENT } from "@angular/common";
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { CommonDialogComponent } from '@procurant/sharedcomponent/components/common-dialog/common-dialog.component';
import { TranslateService } from 'common-lib';
import { OneAuthService } from 'one-auth';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';



export const skipTokenHeader = 'X-Skip-Authorization';
export const skipJSONContentTypeHeader = 'X-Skip-Content-Type';


@Injectable({
    providedIn: 'root'
})

export class HttpRequestInterceptor implements HttpInterceptor {
    public dialogRef: any;

    constructor(private auth: OneAuthService, @Inject(DOCUMENT) document: any, private translate: TranslateService, public snackBar: MatSnackBar, public msgDiag: MatDialog, private router: Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.headers.has(skipTokenHeader)) {
            return next.handle(request);
        }
        else {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${this.auth.getToken()}`
                }
            });
        }

        return next.handle(request).pipe(
            //retry(1),
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    // console.log('event: ', event);
                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => { //Need More Work
                let url: string = document.location.href;
                let ae = this.translate.instant('LANG.AUTH_ERROR');
                let se = this.translate.instant('LANG.SERVICE_ERROR');
                let errmsg = "";
                // console.log(error);
                // let errordetails = {};
                // errordetails = {
                //     reason: error && error.error.error ? error.error.error: '',
                //     message: error && error.error.message ? error.error.message: '',
                //     status: error.status
                // }
                if (error.status == 200) {

                }
                if (error.status == 401 && !url.includes('localhost')) {
                    // Auth Issue, navigate to login
                    // console.log('navigate to login');
                    let msg = {
                        title: 'Authentication Error',
                        error: 'We were unable to Authenticate you! Please try logging in again',
                        code: 'E1004011001'
                    }

                    this.openDialog(msg);

                    errmsg = this.translate.instant('LANG.AUTH_ERROR_MSG') + "!";

                    // this.alert.messageAlert(ae, errmsg, ['ok']);
                    // this.navCtrl.navigateRoot('user-login');
                } else if (error.status == 1400) {
                    errmsg = this.translate.instant('LANG.TOKEN_ERROR_MSG') + "!";
                    let msg = {
                        title: 'Error',
                        error: 'There seems to be an Error! Sometimes logging in agian may resolve the issue. If the issue persists, please contact us',
                        code: 'E1004001001'
                    }

                    this.openDialog(msg);
                    // this.alert.messageAlert(ae, errmsg, ['ok']);
                } else if (error.status == 1405) {
                    errmsg = this.translate.instant('LANG.TOKEN_ERROR_MSG') + "!";
                    // console.log('not allowed');
                    let msg = {
                        title: 'Request Error',
                        error: 'Oops! looks like we hit a snag communicating with the service. If the issue persist please contact us!',
                        code: 'E1005001001'
                    }

                    this.openDialog(msg);
                    // this.alert.messageAlert(ae, errmsg, ['ok']);

                } else if (error.status == 1500) {
                    // Bad Request / Internal Server Error
                    errmsg = this.translate.instant('LANG.SERVICE_ERROR_MSG') + "!";
                    // console.log("Bad Request Error");
                    let msg = {
                        title: 'Request Error',
                        error: 'Oops! looks like we hit a snag communicating with the service. If the issue persist please contact us!',
                        code: 'E1005001001'
                    }

                    this.openDialog(msg);
                    // this.alert.messageAlert(se, errmsg, ['ok']);
                } else if (error.status == 1404) {
                    // API Service not found
                    errmsg = this.translate.instant('LANG.SERVICE_NOTFOUND_MSG') + "!";

                    let msg = {
                        title: 'Service Not Found',
                        error: 'We are having issues locating the service. If the issue persist please contact us!',
                        code: 'E1004041001'
                    }

                    this.openDialog(msg);
                    // this.alert.messageAlert(se, errmsg, ['ok']);
                } else if (error.status == 502) {
                    // API Service is down
                    errmsg = this.translate.instant('LANG.SERVICE_DOWN_MSG') + "!";
                    let msg = {
                        title: 'Service Down',
                        error: 'Service is down. Please try again later!',
                        code: 'E1005021001'
                    }

                    this.openDialog(msg);

                }
                return throwError(error);
                // return throwError(error);
            })
        );
    }

    openDialog(msg) {
        if (!this.dialogRef) {
            this.dialogRef = this.msgDiag.open(CommonDialogComponent, { data: msg });
        }


        this.dialogRef.afterClosed().subscribe(result => {
            this.dialogRef = undefined;
            if (msg.code == 'E1004011001') {
                this.auth.clearSession();
                sessionStorage.clear();
                sessionStorage.setItem('lang', 'en_US');
                //this.auth.logoutUser().subscribe(result => {
                this.router.navigate(['login']);
                //});
            }
            if (msg.code == 'E1005021001') {
                this.auth.clearSession();
                sessionStorage.clear();
                sessionStorage.setItem('lang', 'en_US');
                //this.auth.logoutUser().subscribe(result => {
                this.router.navigate(['login']);
                //});
            }
        });
    }

}